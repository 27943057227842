body {
  background: #111f26; }

:focus-visible {
  outline: none; }

.common-button {
  background-color: #8f6599;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: none;
  color: #fff;
  width: 100%;
  margin-top: 25px;
  padding: 8px;
  cursor: pointer; }
  .common-button.btn:focus {
    outline: 0;
    box-shadow: none; }
  .common-button.border-variant {
    background-color: transparent;
    border: 1.5px solid #8f6599;
    color: #8f6599; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px; }
  .header .back {
    cursor: pointer; }
  .header-actionDropdown .toggle-button {
    padding: 0;
    background-color: transparent;
    border: none; }
    .header-actionDropdown .toggle-button:focus {
      box-shadow: none; }
  .header-actionDropdown-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%; }

.searchInput {
  margin: 25px 0;
  position: relative; }
  .searchInput input {
    width: 100%;
    background: #111f26;
    border: 1px solid #8f6599;
    box-sizing: border-box;
    border-radius: 49px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #8f6599;
    padding-left: 25px; }
  .searchInput svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%); }

.textInput input {
  width: 100%;
  background: none;
  border: 1px solid #8f6599;
  box-sizing: border-box;
  border-radius: 49px;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8f6599; }

.selectInput-wrapper {
  overflow: hidden; }
  .selectInput-wrapper .selectInput {
    width: 100%;
    text-overflow: ellipsis;
    background-color: transparent !important;
    border: 1px solid #8f6599 !important;
    box-sizing: border-box;
    border-radius: 49px !important;
    padding: 8px 20px !important;
    padding-right: 30px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #8f6599 !important;
    height: auto !important; }
    .selectInput-wrapper .selectInput-option {
      text-overflow: ellipsis; }
  .selectInput-wrapper:not(.is-multiple):not(.is-loading)::after, .selectInput-wrapper:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #8f6599; }

.custom-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center; }
  .custom-modal.show {
    display: flex; }
  .custom-modal .modal-content {
    position: relative;
    background: #313644;
    border-radius: 5px;
    width: 80%;
    text-align: center;
    padding: 35px 20px; }
    .custom-modal .modal-content .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 40px; }
    .custom-modal .modal-content .close {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px; }

.login_logo {
  width: 100%;
  text-align: center;
  margin-bottom: 60px; }
  .login_logo-img {
    max-width: 100%;
    height: 70px; }

.login_form-control span {
  left: 10px !important;
  top: 50% !important;
  transform: translateY(-50%); }

.login_text-input {
  border-radius: 68px;
  background-color: #242832;
  padding-left: 4em !important;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  width: 100%;
  box-shadow: none;
  border: none;
  height: 60px;
  padding: 25px; }

.dashboard_main {
  color: #ffffff; }
  .dashboard_main p {
    margin-bottom: 0; }
  .dashboard_main-avatar {
    width: 76px;
    height: 76px;
    border-radius: 100%;
    margin-bottom: 12px; }
  .dashboard_main-hi {
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #8f6599; }
  .dashboard_main-welcome {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px; }
  .dashboard_main-select-text {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px; }
  .dashboard_main-option {
    background: #242832;
    border-radius: 5px;
    margin-top: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #fff; }
  .dashboard_main_sign-out-btn {
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    bottom: 48px;
    left: 38px; }

.dashboard .hero-body {
  align-items: initial !important;
  padding-top: 93px; }

@media (min-width: 768px) {
  .dashboard .dashboard_main {
    text-align: center; }
  .dashboard .hero-body {
    align-items: center !important;
    padding-top: initial; } }

.devices .hero-body {
  align-items: initial !important;
  padding-top: 33px; }

.devices_main {
  color: #fff; }
  .devices_main-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 0; }
  .devices_main-sub-heading {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; }
  .devices_main-device-card {
    background: #242832;
    mix-blend-mode: normal;
    border-radius: 5px;
    margin: 7px 0;
    padding: 10px;
    cursor: pointer; }
    .devices_main-device-card img {
      min-height: 100px;
      width: auto;
      object-fit: contain; }

.devices_preview.empty {
  display: flex;
  align-items: center;
  justify-content: center; }

.devices_preview-info {
  margin-top: 50%;
  font-size: 14px;
  line-height: 19px;
  color: #8f6599; }

.devices_preview .back {
  margin: 10px 0;
  cursor: pointer; }

.devicesByBrand_list {
  margin: 20px 0;
  overflow: auto;
  max-height: 70vh; }
  .devicesByBrand_list-item {
    position: relative;
    cursor: pointer;
    background: #242832;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 12px 10px; }
    .devicesByBrand_list-item p {
      margin-bottom: 0; }
    .devicesByBrand_list-item-name {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px; }
    .devicesByBrand_list-item-deviceType {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px; }
    .devicesByBrand_list-item-version {
      position: absolute;
      right: 10px;
      bottom: 12px;
      font-size: 10px;
      line-height: 14px;
      color: #c4c4c4; }
    .devicesByBrand_list-item-details {
      margin-top: 12px;
      border-top: 0.4px solid #575757;
      padding-top: 10px; }
      .devicesByBrand_list-item-details-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 3px; }
        .devicesByBrand_list-item-details-row:last-child {
          margin-bottom: 0; }
        .devicesByBrand_list-item-details-row span {
          max-width: 50%;
          font-size: 14px;
          line-height: 19px;
          color: #fff; }
          .devicesByBrand_list-item-details-row span:last-child {
            color: #6b6b6b;
            text-align: right; }

.add-device-form__column-group {
  text-align: initial; }
  .add-device-form__column-group .column {
    padding-top: 10px;
    padding-bottom: 10px; }

.add-device-form_footer {
  display: flex;
  align-items: center;
  justify-content: center; }
  .add-device-form_footer .submit-add-new-device {
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px; }

.add-device-form .input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 7px; }

.deviceCard-item {
  padding: 12px 14px; }

.deviceCard-footer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap; }
  .deviceCard-footer .btn {
    min-width: 90px;
    margin-top: 10px; }

.DeviceCommandModal .prompt-modal-content-footer {
  display: flex;
  align-items: center;
  justify-content: center; }
  .DeviceCommandModal .prompt-modal-content-footer-btn {
    width: 120px;
    margin: 0 3px; }

.DeviceCommandModal .update-modal-content {
  overflow-y: scroll; }
  .DeviceCommandModal .update-modal-content-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    background: #242832;
    border-radius: 5px;
    padding: 16px 20px; }

.custom-toast-success {
  background-color: green; }

@media (min-width: 992px) {
  .deviceCard-footer {
    display: flex;
    flex-wrap: wrap; }
    .deviceCard-footer .btn {
      margin: 5px 5px;
      flex: 0 1 45%; } }

@media (min-width: 1200px) {
  .deviceCard-footer {
    display: flex; }
    .deviceCard-footer .btn {
      min-width: 100px;
      margin: 10px 5px;
      flex: 1; } }

.device_uninstall {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.device_uninstall input {
  max-width: 500px;
  flex: 1; }

button.no-hover:hover {
  background: #584f79;
  color: white; }

.select-brand-list .list-group {
  max-height: 60vh;
  overflow-y: auto;
  background: transparent;
  color: white; }

.select-brand-list li {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .select-brand-list li .btn-move {
    max-width: 100px; }

.select-brand-list .brand-detail {
  display: flex;
  gap: 20px;
  color: white;
  flex: 1; }
  .select-brand-list .brand-detail img {
    height: 100%;
    width: auto;
    object-fit: cover;
    aspect-ratio: 1/1; }

.modal-content:has(.select-brand-list) {
  max-width: 700px; }
